
/* eslint-disable @typescript-eslint/camelcase */
import {
  defineComponent,
  ref,
  toRefs,
  computed,
  watch
} from 'vue';

import { getAdminAllocatedMembers } from '@/services/api';

export default defineComponent({
  props: {
    adminId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { adminId, modelValue } = toRefs(props);
    const page = ref(1);
    const createdDateRange = ref();
    const startDate = ref();
    const endDate = ref();

    const isVisible = computed({
      get: () => modelValue.value,
      set: (isVisible) => emit('update:modelValue', isVisible)
    });

    const data = ref();
    const isLoading = ref(false);

    const fetchAdminAllocatedMembers = async() => {
      isLoading.value = true;

      data.value = (await getAdminAllocatedMembers({
        adminId: adminId.value,
        query: {
          page: page.value,
          perPage: 10,
          startDate: startDate.value,
          endDate: endDate.value,
          sortBy: 'created_at'
        }
      }));

      isLoading.value = false;
    };

    const getCreateDateRange = () => {
      if (createdDateRange.value) {
        startDate.value = createdDateRange.value[0];
        endDate.value = createdDateRange.value[1];
      } else {
        startDate.value = null;
        endDate.value = null;
      }
    };

    watch(isVisible, (isVisible) => {
      if (isVisible) fetchAdminAllocatedMembers();
      if (!isVisible) {
        createdDateRange.value = null;
        getCreateDateRange();
      }
    });

    watch([page, startDate, endDate], () => {
      fetchAdminAllocatedMembers();
    });

    return {
      page,
      data,
      isLoading,
      isVisible,
      createdDateRange,
      getCreateDateRange
    };
  }
});
