
import { defineComponent, ref, onMounted } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useWhitelists, useDeleteIpAddress } from '@/composables/api';
import { IpWhitelistIdOptions, getIpWhitelists } from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const route = useRoute();
    const page = ref(1);
    const keyword = ref();
    const { data, isLoading, isFetching, refetch } = useWhitelists({ page, keyword });
    const { isLoading: isDeletedLoading, mutate } = useDeleteIpAddress();

    const statusIndex = ref(undefined);

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const deleteWhitelistId = ({ whitelistId }: IpWhitelistIdOptions, index: Number) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { whitelistId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    onMounted(async() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      isLoading,
      isFetching,
      data,
      isDeletedLoading,
      deleteWhitelistId,
      statusIndex,
      searchKeyword
    };
  }
});
